import {Component} from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-standalone
@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: [],
  standalone: false
})
export class ExampleComponent {
}
